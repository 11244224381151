<template>
	<div class="panel overflow-hidden theme-light">
		<div class="container sk mt-2">
			<div class="panel_header">
				<div class="row align-items-end">
					<div class="col-12">
						<h2 class="title title-lg">
							О программах
						</h2>
						<div v-if="$route.name === 'navigator-about-pogrammes' " class="d-flex justify-content-between align-items-baseline flex-wrap">
							<search-line-menu :links="links" class="mb-3" />
						</div>

						<p>Школа придерживается философии Lifelong Learning (обучение на протяжении всей жизни) и разрабатывает образовательные программы и сервисы для развития себя, своего бизнеса, отрасли или страны.</p>

					</div>
				</div>
			</div>

			<div class="overflow-hidden ">
				<div class="row ml-n4">

					<div class="col-12 col-md-6 col-lg-3 pl-4 pt-4 pr-0 mb-md-4 border-top">
						<div class="d-flex flex-column justify-content-between h-100 pr-4 border-md-right">
							<div class="item title mb-2">
								Senior Executives Programmes
							</div>
							<div class="mb-4 mb-md-1">
								<a href="/navigator/programmes/?page=1&search=&taxonomy=185"  class="more_link">Посмотреть программы</a>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6 col-lg-3 pl-4 pt-4 pr-0 mb-md-4 border-top">
						<div class="d-flex flex-column justify-content-between h-100 pr-4 border-md-right">
							<div class="item title mb-2">
								MBA программы
							</div>
							<div class="mb-4 mb-md-1">
								<a href="/degree/" class="more_link">Посмотреть программы</a>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6 col-lg-3 pl-4 pt-4 pr-0 mb-md-4 border-top">
						<div class="d-flex flex-column justify-content-between h-100 pr-4 border-md-right">
							<div class="item title mb-2">
								 Программы для учащихся 8-11 классов
							</div>
							<div class="mb-4 mb-md-1">
								<a href="/school-student/" class="more_link">Посмотреть программы</a>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6 col-lg-3 pl-4 pt-4 pr-0 mb-md-4 border-top">
						<div class="d-flex flex-column justify-content-between h-100 pr-4 border-md-right">
							<div class="item title mb-2">
								 Программы бакалавриата
							</div>
							<div class="mb-4 mb-md-1">
								<a href="/bachelor-programmes/" class="more_link">Посмотреть программы</a>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6 col-lg-3 pl-4 pt-4 pr-0 mb-md-4 border-top">
						<div class="d-flex flex-column justify-content-between h-100 pr-4 border-md-right">
							<div class="item title mb-2">
								 Программы для молодых специалистов
							</div>
							<div class="mb-4 mb-md-1">
								<a href="/young-professionals/" class="more_link">Посмотреть программы</a>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6 col-lg-3 pl-4 pt-4 pr-0 mb-md-4 border-top">
						<div class="d-flex flex-column justify-content-between h-100 pr-4 border-md-right">
							<div class="item title mb-2">
								Программы для запуска и развития бизнеса
							</div>
							<div class="mb-4 mb-md-1">
								<a href="/msb/" class="more_link">Посмотреть программы</a>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6 col-lg-3 pl-4 pt-4 pr-0 mb-md-4 border-top">
						<div class="d-flex flex-column justify-content-between h-100 pr-4 border-md-right">
							<div class="item title mb-2">
								 Программы по лидерству и развитию профессиональных компетенций
							</div>
							<div class="mb-4 mb-md-1">
								<a href="/leadership-competency-development/" class="more_link">Посмотреть программы</a>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6 col-lg-3 pl-4 pt-4 pr-0 mb-md-4 border-top">
						<div class="d-flex flex-column justify-content-between h-100 pr-4 border-md-right">
							<div class="item title mb-2">
								 Программы для отрасли образования
							</div>
							<div class="mb-4 mb-md-1">
								<a href="/research-and-education/" class="more_link">Посмотреть программы</a>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6 col-lg-3 pl-4 pt-4 pr-0 mb-md-4 border-top">
						<div class="d-flex flex-column justify-content-between h-100 pr-4 border-md-right">
							<div class="item title mb-2">
								 Программы для отрасли здравоохранения
							</div>
							<div class="mb-4 mb-md-1">
								<a href="/healthcare-programs/" class="more_link">Посмотреть программы</a>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6 col-lg-3 pl-4 pt-4 pr-0 mb-md-4 border-top">
						<div class="d-flex flex-column justify-content-between h-100 pr-4 border-md-right">
							<div class="item title mb-2">
								Решения для государственного и общественного сектора
							</div>
							<div class="mb-4 mb-md-1">
								<a href="/b2g-programmes/" class="more_link">Посмотреть программы</a>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6 col-lg-3 pl-4 pt-4 pr-0 mb-md-4 border-top">
						<div class="d-flex flex-column justify-content-between h-100 pr-4 border-md-right">
							<div class="item title mb-2">
								 Коучинг, менторство, карьерное консультирование
							</div>
							<div class="mb-4 mb-md-1">
								<a href="/centres/coaching/" class="more_link">Посмотреть программы</a>
							</div>
						</div>
					</div>


					<div class="col-12 col-md-6 col-lg-3 pl-4 pt-4 pr-0 mb-md-4 border-top">
						<div class="d-flex flex-column justify-content-between h-100 pr-4 border-md-right">
							<div class="item title mb-2">
								 Кастомизированные образовательные программы для компаний
							</div>
							<div class="mb-4 mb-md-1">
								<a href="/corporate/" class="more_link">Посмотреть программы</a>
							</div>
						</div>
					</div>

				</div>
				<div class="row">
					<div class="col-12 col-lg-12">
						<div class="border-lg-top h-100 d-flex justify-content-center flex-column py-lg-4">
							<div>
								<h4 class="title">
									Не увидели подходящую программу?
								</h4>
								<p>Чтобы получить всю информацию об интересующей вас программе, свяжитесь с нашим консультантом или подберите программу</p>
							</div>
							<div class="btn-panel">
								<a
									role="button"
									href="/navigator/programmes/"
									class="btn btn-primary"
								>Подобрать программу</a>
								<a
									role="button"
									onclick="return jivo_api.open(),!1"
									href="javascript:void(0)"
									class="ml-3 btn btn-outline-primary"
								>{{ $t('ChatWithAdvisor') }}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import panels from '~/mixins/panels';
import SearchLineMenu from '@/components/layouts/_partials/SearchLineMenu.vue';
import links from '@/pages/navigator/links';
import createJivoMixin from '~/mixins/createJivoMixin';

export default {
	name: 'PanelNav02',
	mixins: [panels, createJivoMixin('fetchedData.consultation_chat_code')],
	components: { SearchLineMenu },
	data() {
		return {
			links,
		};
	},
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/components/layouts/event-search-header";

	.dropdown-toggle {
		white-space: normal;
		cursor: pointer;

		&:focus {
			outline: none !important;
		}
	}

	.search-header {
		background-color: #FFFFFF;
		margin-bottom: 0;
		padding-bottom: 1rem;
	}

	.panel_header {
		margin-bottom: 0;
	}

	.dropdown-toggle {
		white-space: normal;
		cursor: pointer;

		&:focus {
			outline: none !important;
		}

		&::after {
			//			display: none;
		}
	}
</style>
